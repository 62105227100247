import styles from './Product.module.scss';
import selectStyles from 'components/primitives/form/Select.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import UomTitle, { createUomTextKey } from './UomTitle';
import { Select } from 'components/primitives/form';
import { UseSanaTexts, SimpleText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';

const UomSelector = ({ productId, allowUOMSelection, uomId, uoms, onUomChange, className = '' }) => {
  const uomIdUpper = uomId.toUpperCase();
  const currentUom = uoms.find(u => u.id.toUpperCase() === uomIdUpper) || {};
  const showUomSelection = allowUOMSelection && uoms.length > 1;

  const sanaTextsToLoad = uoms.map(uom => ({ key: createUomTextKey(uom.id), fallback: uom.description || uom.id }));
  sanaTextsToLoad.disableInsiteEditor = true;

  const selectorId = showUomSelection ? `${productId}_UOM` : null,
    labelId = selectorId && selectorId + 'lb';

  const uomSelectorPlaceholder = <Placeholder className={styles.uomSelectorPlaceholder} />;

  return (
    <div className={`${styles.uom} ${className} ${showUomSelection ? '' : 'no-uom-selection'}`}>
      {showUomSelection && (
        <UseSanaTexts options={sanaTextsToLoad} placeholder={uomSelectorPlaceholder}>
          {uomTitles => {
            const uomItems = uoms.map((uom, index) => ({ name: makeSimpleText(uomTitles[index]), value: uom.id || index }));
            return (
              <Select
                items={uomItems}
                className={selectStyles.small}
                onChange={onUomChange}
                value={currentUom.id}
                id={selectorId}
                labelId={labelId}
                optionClassName={styles.option}
                shouldReset={productId}
              />
            );
          }}
        </UseSanaTexts>
      )}
      <label id={labelId} className="visually-hidden">
        <SimpleText textKey="UnitOfMeasure" placeholder={uomSelectorPlaceholder} />
      </label>
      {!showUomSelection &&
        <div className={styles.uomTitle}>
          <UomTitle id={currentUom.id} description={currentUom.description} />
        </div>
      }
    </div>
  );
};

UomSelector.propTypes = {
  productId: PropTypes.string.isRequired,
  allowUOMSelection: PropTypes.bool,
  uomId: PropTypes.string,
  uoms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    quantityStep: PropTypes.number.isRequired,
  })),
  onUomChange: PropTypes.func,
  className: PropTypes.string,
};

export default memo(UomSelector);
